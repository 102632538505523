'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');
var util = require('./util');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

let topDistance = function () {
    let header = $(document.querySelector('header'));
    let headerBanner = $(document.querySelector('.header-banner'));
    let positionTop = headerBanner.height() - $(window).scrollTop();

    if (positionTop >= 0) {
        $('.dropdown-menu-all').css('top', positionTop + header.height());
    } else {
        $('.dropdown-menu-all').css('top', header.height());
    }

    if ($(window).width() < 992) {
        $('.dropdown-menu-all').css('top', 0);
    }
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    $(window).on('scroll', topDistance);

    keyboardAccessibility('.main-menu .menuNav-link, .main-menu .dropdown-link, .main-menu .separated-item, .promo-banner > a',
        {
            40: function (menuItem) { // down
                const firstLink = menuItem.find('ul > li > a').first();
                const nextMenuItem = menuItem.next();
                const parentMenuItems = menuItem.parent().parent().find('a');
                const subLinkItems = menuItem.find('.dropdown-menu li > a');

                if (menuItem.hasClass('nav-menuNavItem')) {
                    firstLink.focus();
                } else if (menuItem.hasClass('dropdown-item') || menuItem.hasClass('dropdown')) {
                    if (menuItem.hasClass('sub-link')) {
                        if (!nextMenuItem.length) { // if this is the last menuItem
                            parentMenuItems.first().focus(); // set focus to the first menuitem
                        } else {
                            nextMenuItem.children().first().focus();
                        }
                    } else {
                        subLinkItems.first().focus();
                    }
                } else if (!nextMenuItem.length) { // if this is the last menuItem
                    parentMenuItems.first().focus(); // set focus to the first menuitem
                } else {
                    nextMenuItem.children().first().focus();
                }
            },
            39: function (menuItem) { // right
                const parentMenuItems = menuItem.parent().parent();
                const nextMenuItem = menuItem.next();
                const nextParentMenuItem = parentMenuItems.next();

                if (!menuItem.hasClass('nav-menuNavItem')) {
                    if (menuItem.parent().hasClass('separated-category')) {
                        parentMenuItems.parent()
                            .find('.dropdown-menu')
                            .first()
                            .find('a')
                            .first()
                            .focus();
                    } else if ((menuItem.hasClass('sub-link') && !nextParentMenuItem.length) || (!nextMenuItem.length && !nextParentMenuItem.length)) {
                        parentMenuItems.find('a').first().focus();
                    } else if (menuItem.hasClass('sub-link') || (!nextMenuItem.length && nextParentMenuItem.hasClass('promo-banner'))) {
                        nextParentMenuItem.find('a').first().focus();
                    } else {
                        nextMenuItem.find('a').first().focus();
                    }
                }
            },
            38: function (menuItem) { // up
                const prevMenuItem = menuItem.prev();
                const parentMenuItems = menuItem.parent().parent().find('a');
                const subLinkItems = menuItem.find('.dropdown-menu li > a');

                if (menuItem.hasClass('dropdown-item') || menuItem.hasClass('dropdown')) {
                    if (menuItem.hasClass('sub-link')) {
                        if (!prevMenuItem.length) { // if this is the first menuItem
                            parentMenuItems.first().focus();
                        } else {
                            prevMenuItem.children().first().focus();
                        }
                    } else {
                        subLinkItems.last().focus();
                    }
                } else if (!prevMenuItem.length) { // if this is the first menuItem
                    parentMenuItems.last().focus(); // set focus to the last menuitem
                } else {
                    prevMenuItem.children().first().focus();
                }
            },
            37: function (menuItem) { // left
                const prevMenuItem = menuItem.prev();
                const parentMenuItems = menuItem.parent().parent();
                const parentMenuItemsNext = parentMenuItems.parent().next();

                if (!menuItem.hasClass('nav-menuNavItem')) {
                    if (!prevMenuItem.length && menuItem.hasClass('sub-link') && parentMenuItems.prev().length) {
                        parentMenuItems.prev().find('a')
                            .first()
                            .focus();
                    } else if (!prevMenuItem.length && parentMenuItems.hasClass('separated-category-all') && !parentMenuItemsNext.length) {
                        parentMenuItems.next().find('a.dropdown-toggle').last().focus();
                    } else if (prevMenuItem.hasClass('categories-container') && !parentMenuItemsNext.length) {
                        prevMenuItem.find('a.dropdown-toggle').last().focus();
                    } else if (!prevMenuItem.length && parentMenuItems.hasClass('categories-container')) {
                        parentMenuItems
                            .find('.separated-category')
                            .children()
                            .first()
                            .children()
                            .focus();
                    } else if (!prevMenuItem.length && parentMenuItems.hasClass('dropdown-item')) {
                        parentMenuItems.parent().parent()
                            .find('.separated-category')
                            .children()
                            .first()
                            .children()
                            .focus();
                    } else if (parentMenuItemsNext.length) {
                        parentMenuItemsNext.find('a')
                            .last()
                            .focus();
                    } else {
                        prevMenuItem.find('a').first().focus();
                    }
                }
            },
            27: function (menuItem) { // escape
                menuItem.closest('.dropdown-menu-all').prev().focus();
            },
            9: function (menuItem) { // tab
                util.removeMenuDropDown(menuItem);

                menuItem.next().children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle-mobile="dropdown-mobile"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                $(this).siblings('.dropdown-menu-all').find('.dropdown-menu').removeClass('showDropdown dropdown-menu-vertical');
                // copy parent element into current UL
                if (!$(this).is('[data-toggle-desktop]')) {
                    var li = $('<li class="dropdown-item top-category" role="button"></li>');
                    li.append($(this).siblings('.dropdown-link').clone());
                    var closeMenu = $('<li class="nav-menu"></li>');
                    closeMenu.append($('.close-menu').first().clone());
                    $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                    $(this).parent().addClass('show');
                }
                // copy navigation menu into view
                $(this).siblings('.dropdown-menu-all').addClass('show');
                $(this).attr('aria-expanded', 'true');
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                if ($(this).is('[data-toggle-desktop]') && $(this).attr('aria-expanded') === 'true') {
                    if ($(this).parent().hasClass('choosed-category')) {
                        window.location.replace($(this).attr('href'));
                    }
                    $(this).parent().addClass('choosed-category');
                    $(this).parent().siblings('.nav-menuNavItem').children('.dropdown-menu-all')
                        .removeClass('show');
                    $(this).parent().siblings('.nav-menuNavItem').removeClass('choosed-category');
                    $(this).parent().siblings('.nav-menuNavItem').children('menuNav-link')
                        .attr('aria-expanded', false);
                }
                e.preventDefault();
            }
        });

    $('.dropdown:not(.disabled) [data-toggle-desktop="dropdown-desktop"]')
    .on('mouseenter focusin', function () {
        if (isDesktop(this)) {
            topDistance();
            $(this).css('font-family', 'NeueHaasDisplayBold');
            $(this).siblings('.dropdown-menu-all').addClass('showDropdown');
            $(this).siblings('.dropdown-menu-all').find('.dropdown-menu').addClass('showDropdown dropdown-menu-vertical');
            $(this).attr('aria-expanded', 'true');
        }
    })
    .parent()
    .on('mouseleave', function () {
        if (isDesktop(this)) {
            $(this).children('a').css('font-family', 'NeueHaasDisplay');
            $(this).children('.dropdown-menu-all').removeClass('showDropdown');
            $(this).children('.dropdown-menu-all').find('.dropdown-menu').removeClass('showDropdown');
            $(this).children('.menuNav-link').attr('aria-expanded', 'false');
        }
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
        $('.nav-menuNavItem').removeClass('choosed-category');
    });

    $('.navbar-toggler').click(function (e) {
        e.preventDefault();
        $('.main-menu').toggleClass('in');
        $('.modal-background').show();

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();

        var choosedCategory = $($('.navbar-menuNav').children()[0]);
        choosedCategory.addClass('choosed-category');
        choosedCategory.find('.dropdown-menu-all').addClass('show');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            if ($(window).width() > 960) {
                $('.navbar-header .user .popover').addClass('show');
            }
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
    });

    $('.navbar-header .wishlist').on('mouseenter focusin', function () {
        if ($('.navbar-header .wishlist .popover').length > 0) {
            $('.navbar-header .wishlist .popover').addClass('show');
            $('.wishlist').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .wishlist').on('mouseleave', function () {
        $('.navbar-header .wishlist .popover').removeClass('show');
        $('.wishlist').attr('aria-expanded', 'false');
    });

    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
};
