'use strict';
var debounce = require('lodash/debounce');
/**
* @description Initializes the slick slider carousels
*/

var slickCarousel = {

    /**
     * @function
     * @description - caches dom elements used as vars and returns an object
     * @return {Object} - Jquery object for cached elements.
     */
    initCache: function () {
        var $cache = {
            slickElement: $(document).find('.slick-slider')
        };

        return $cache;
    },

    /**
     * @function
     * @description - initializes each instance of the carousel found, based on the param passed
     * @param {element} element - selector or element to use as slick element.
     */
    initSlickSlider: function (element) {
        var $element = $(element);

        $element.each(function () {
            var $this = $(this);

            if ($this.hasClass('slick-initialized')) {
                return;
            }

            $this.slick();
        });

        $element.on('touchstart', function () {
            $element.slick('slickPlay');
        });

        $(window).on('resize', debounce(function () {
            if ($element.hasClass('slick-initialized')) {
                $element.slick('unslick');
            }

            $element.slick();
        }, 300));
    },

    init: function () {
        var $cache = this.initCache();

        if (!$cache.slickElement) {
            $cache.slickElement = $('.slick-slider');
        }

        this.initSlickSlider($cache.slickElement);
    }
};

module.exports = slickCarousel;
