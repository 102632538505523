'use strict';
var util = require('./util');

module.exports = function (selector, keyFunctions, preFunction) {
    $(selector).on('keydown', function (e) {
        var key = e.which;
        var supportedKeyCodes = [37, 38, 39, 40, 27, 9];
        var returnedScope = preFunction.call(this);

        if (returnedScope.hasClass('nav-menuNavItem') && key === 9 && returnedScope.next().length === 0) {
            util.removeMenuDropDown(returnedScope);
            return;
        }

        if (!returnedScope.hasClass('nav-item')) {
            if (supportedKeyCodes.indexOf(key) >= 0) {
                e.preventDefault();
            }
        }

        if (keyFunctions[key]) {
            keyFunctions[key].call(this, returnedScope);
        }
    });
};
