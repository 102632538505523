'use strict';
/**
 * Return a String with 2 digits number.
 * @param {Integer} n Number to double check (Should be > 0)
 * @returns {string} Double digit number.
 */
function doubleDigit(n) {
    if (n < 10 && n > -1) {
        return '0' + n;
    }

    return '' + n;
}

/**
 * Update and check the date.
 * @param {JQuery|DOM} dateField Field with complete date sent to the backend
 * @param {JQuery|DOM} dayField Select field for day
 * @param {JQuery|DOM} monthField Select field for month
 * @param {JQuery|DOM} yearField Select field for year
 */
function updateDateElements(dateField, dayField, monthField, yearField) {
    var $date = $(dateField);
    var $day = $(dayField);
    var $month = $(monthField);
    var $year = $(yearField);

    // Don't compute date if some fields are not selected yet
    if ($year.val() * $month.val() * $day.val() === 0) {
        return;
    }

    var date = new Date($year.val(), $month.val() - 1, $day.val());

    // Format date as it would be in an <input type="date"> element.
    $date.val(date.getFullYear() + '-' + doubleDigit(date.getMonth() + 1) + '-' + doubleDigit(date.getDate()));

    $day.val(date.getDate());
    $month.val(date.getMonth() + 1);
    $year.val(date.getFullYear());
}


module.exports = {
    selectPredefinedAmount: function () {
        $('.predefined-value span').on('click', function () {
            $('#gift-cert-amount').val($(this).data('value'));
            $('.predefined-value span').removeClass('active');
            $(this).addClass('active');
        });
    },

    changeGiftCertAmount: function () {
        $('#gift-cert-amount').on('keyup focusout mouseleave', function () {
            if ($(this).val()) {
                $('.predefined-value span').removeClass('active');
                var predefinedAmountSelector = '.predefined-value span[data-value=' + $(this).val() + ']';
                var $predefinedAmount = $(predefinedAmountSelector);
                if ($predefinedAmount.length > 0) {
                    $predefinedAmount.addClass('active');
                }
            }
        });
    },

    updateRegistrationDatePickers: function () {
        var $date = $('.js-delivery-dob');
        var $day = $('.js-delivery-dob-day');
        var $month = $('.js-delivery-dob-month');
        var $year = $('.js-delivery-dob-year');

        $day.on('change', function () {
            updateDateElements($date, $day, $month, $year);
        });

        $month.on('change', function () {
            updateDateElements($date, $day, $month, $year);
        });

        $year.on('change', function () {
            updateDateElements($date, $day, $month, $year);
        });
    },

    initEditProfileBirthdayDatePickers: function () {
        var $date = $('.js-delivery-dob');
        var $year = $('.js-delivery-dob-year');
        var $month = $('.js-delivery-dob-month');
        var $day = $('.js-delivery-dob-day');

        if ($date.val()) {
            var birthdayDateParts = $date.val().split(/[-/\s]/);

            if (birthdayDateParts.length === 3
                && !(isNaN(birthdayDateParts[0]) || isNaN(birthdayDateParts[1]) || isNaN(birthdayDateParts[2]))) {
                $year.val(parseInt(birthdayDateParts[0], 10));
                $month.val(parseInt(birthdayDateParts[1], 10));
                $day.val(parseInt(birthdayDateParts[2], 10));
            }
        }
    }
};
