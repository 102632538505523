'use strict';

var scrollAnimate = require('./scrollAnimate');
var util = require('./util');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    $('.email-signup-message').remove();
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        if (button.hasClass('subscribe-email')) {
            $('body').append(
                '<div class="email-signup-message"></div>'
             );
        } else {
            $('#newsletterForm').prepend(
                '<div class="email-signup-message"></div>'
             );
        }
    }

    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/**
 * validate the newsletterForm form
 * @param {string} form - newsletterForm
 */
function formValidate(form) {
    var email = form.find('input[name$=_email]');
    var checkbox = form.find('input[name$=_subscribeNewsletter]');
    var country = form.find('select[name$=_countryCode]');
    var emailReg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; //eslint-disable-line
    var validationMessage;
    var validationMessageCountry;
    var validationMessageCheckbox;

    if (email.val().length === 0) {
        email.addClass('is-invalid');
        validationMessage = email.data('missing-error');
    } else if (!emailReg.test(email.val())) {
        email.addClass('is-invalid');
        validationMessage = email.data('valid-email');
    } else {
        email.removeClass('is-invalid');
    }

    if (!checkbox.is(':checked')) {
        checkbox.addClass('is-invalid');
        validationMessageCheckbox = checkbox.data('missing-error');
    } else {
        checkbox.removeClass('is-invalid');
    }

    if (!country.val()) {
        country.addClass('is-invalid');
        validationMessageCountry = country.data('missing-error');
    } else {
        country.removeClass('is-invalid');
    }

    email.parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
    checkbox.parents('.form-check').find('.invalid-feedback')
                    .text(validationMessageCheckbox);
    country.parents('.form-group').find('.invalid-feedback')
    .text(validationMessageCountry);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('#giftcert-message').on('input', function () {
        var maxlength = $(this).attr('maxlength');
        var currentLength = $(this).val().length;
        $('#count').text(maxlength - currentLength);
    });

    $('body').on('click', '#newsletterForm .submitBtn', function (e) {
        e.preventDefault();
        var $newsletterForm = $('#newsletterForm');
        var url = $newsletterForm.attr('action');
        var button = $(this);
        var gender = $newsletterForm.find('#gender').val();
        var emailId = $newsletterForm.find('input[name$=_email]').val();
        var country = $newsletterForm.find('#country').val();
        var subscribed = $newsletterForm.find('input[name$=_subscribeNewsletter]').is(':checked');
        $.spinner().start();
        $(this).attr('disabled', true);

        formValidate($newsletterForm);
        util.focusModal($newsletterForm);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                gender: gender,
                emailId: emailId,
                country: country,
                subscribedBox: subscribed
            },
            success: function (data) {
                $('body').trigger('newsletter:success', data);
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name$=_newsletter_email]').val();
        var country = $('select[name$=_countryCode]').val();
        var interestedIn = $('input[name=interestedIn]:checked').val();

        $.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                country: country,
                gender: interestedIn
            },
            success: function (data) {
                $('body').trigger('newsletter:success', data);
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
};
