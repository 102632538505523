'use strict';

/**
 * Collapse the navigation menu.
 * @param {JQuery} $nav .nav tag JQuery element.
 */
function collapseNavMenu($nav) {
    $nav.find('.nav-item').each(function () {
        var $this = $(this);

        if (!$this.find('.active').length) {
            $this.addClass('menu-hide');
        }
    });

    $nav.removeClass('expended');
    $nav.find('.js-dashboard-controll-wrapper .js-expend').addClass('show');
    $nav.find('.js-dashboard-controll-wrapper .js-collapse').removeClass('show');
}

/**
 * Expend the navigation menu.
 * @param {JQuery} $nav .nav tag Jquery element.
 */
function expandNavMenu($nav) {
    $nav.find('.nav-item').removeClass('menu-hide');

    $nav.addClass('expended');
    $nav.find('.js-dashboard-controll-wrapper .js-expend').removeClass('show');
    $nav.find('.js-dashboard-controll-wrapper .js-collapse').addClass('show');
}

/**
 * Find the current selected item.
 * @param {JQuery} $nav .nav tag JQuery element.
 * @returns {JQuery} The selected item.
 */
function findSelectedItem($nav) {
    var $item = null;

    $nav.find('.nav-item').each(function () {
        var $this = $(this);

        if ($this.find('.active').length) {
            $item = $this;

            // break the loop, there is only one .active element
            return false;
        }

        return true;
    });

    return $item;
}

module.exports = {
    dashboardNavigation: function () {
        var $nav = $('.js-dashboard-nav');

        if ($nav.length > 0) {
            var $currentNavItem = findSelectedItem($nav);
            collapseNavMenu($nav);

            $nav.on('click', function () {
                expandNavMenu($nav);
            });

            if (!$nav.hasClass('js-size-guide-nav')) {
                $currentNavItem.on('click', function (e) {
                    if ($nav.hasClass('expended')) {
                        e.stopPropagation();
                        collapseNavMenu($nav);
                    }
                });
            } else {
                $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
                    collapseNavMenu($nav);
                });
            }

            $('.js-expend').on('click', function (e) {
                e.stopPropagation();
                expandNavMenu($nav);
            });

            $('.js-collapse').on('click', function (e) {
                e.stopPropagation();
                collapseNavMenu($nav);
            });
        }
    }
};
